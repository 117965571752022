import { tns } from "tiny-slider/src/tiny-slider.module";

if (document.querySelector('.slide-wrapper')) {
    var slider = tns({
        container: ".slide-wrapper",
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            }
        },
        autoplay: true,
        autoplayTimeout: 10000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false
    });


    if (document.querySelector('.prev-banner')) {
        document.querySelector('.prev-banner').onclick = function () {
            slider.goTo('prev');
        };
    }

    if (document.querySelector('.next-banner')) {
        document.querySelector('.next-banner').onclick = function () {
            slider.goTo('next');
        };
    }
}

if (document.querySelector('.slide-wrapper-mobile')) {
    var sliderMobile = tns({
        container: ".slide-wrapper-mobile",
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            }
        },
        autoplay: true,
        autoplayTimeout: 10000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false
    });


    if (document.querySelector('.prev-mobile')) {
        document.querySelector('.prev-mobile').onclick = function () {
            sliderMobile.goTo('prev');
        };
    }

    if (document.querySelector('.next-mobile')) {
        document.querySelector('.next-mobile').onclick = function () {
            sliderMobile.goTo('next');
        };
    }
}

if (document.querySelector(".slide-wrapper-servicos")) {
    var sliderServicos = tns({
        container: ".slide-wrapper-servicos",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 5,
            },
        },
        autoplay: true,
        autoplayTimeout: 1000,
        mouseDrag: true,
        loop: true,
        gutter: 15,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-servicos")) {
        document.querySelector(".prev-servicos").onclick = function () {
            sliderServicos.goTo("prev");
        };
    }

    if (document.querySelector(".next-servicos")) {
        document.querySelector(".next-servicos").onclick = function () {
            sliderServicos.goTo("next");
        };
    }
}

if (document.querySelector(".slide-wrapper-consultorio")) {
    var sliderConsultorio = tns({
        container: ".slide-wrapper-consultorio",
        responsive: {
            0: {
                items: 1,
            },
            900: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        },
        autoplay: true,
        autoplayTimeout: 10000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector(".prev-consultorio")) {
        document.querySelector(".prev-consultorio").onclick = function () {
            sliderConsultorio.goTo("prev");
        };
    }

    if (document.querySelector(".next-consultorio")) {
        document.querySelector(".next-consultorio").onclick = function () {
            sliderConsultorio.goTo("next");
        };
    }
}

if (document.querySelector(".slide-wrapper-equipe")) {
    var sliderEquipe = tns({
        container: ".slide-wrapper-equipe",
        items: 4,
        responsive: {
            0: {
                items: 1,
            },
            900: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        },
        autoplay: true,
        autoplayTimeout: 10000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector(".prev-equipe")) {
        document.querySelector(".prev-equipe").onclick = function () {
            sliderEquipe.goTo("prev");
        };
    }

    if (document.querySelector(".next-equipe")) {
        document.querySelector(".next-equipe").onclick = function () {
            sliderEquipe.goTo("next");
        };
    }
}


if (document.querySelector(".slide-wrapper-equipe-desktop")) {
    var sliderEquipeDesktop = tns({
        container: ".slide-wrapper-equipe-desktop",
        items: 4,
        responsive: {
            0: {
                items: 1,
            },
            1200: {
                items: 4,
            }
        },
        autoplay: true,
        autoplayTimeout: 10000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector(".prev-equipe-desktop")) {
        document.querySelector(".prev-equipe-desktop").onclick = function () {
            sliderEquipeDesktop.goTo("prev");
        };
    }

    if (document.querySelector(".next-equipe-desktop")) {
        document.querySelector(".next-equipe-desktop").onclick = function () {
            sliderEquipeDesktop.goTo("next");
        };
    }
}
