require('jquery-mask-plugin')

var SPMaskBehavior = function (val) {
    return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
},
    spOptions = {
        onKeyPress: function (val, e, field, options) {
            field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
    };


$(function () {
    $('.telefone').mask(SPMaskBehavior, spOptions);
    $('.money').mask('0000000.00', { reverse: true });
    $('.cpf').mask('000.000.000-00', { reverse: true });
    $('.cnpj').mask('00.000.000/0000-00', { reverse: true });
    $('.cep').mask('00000-000', { reverse: true });
    $('#ie').mask('00000000000000', { reverse: true });
    $('.data').mask('00/00/0000', { reverse: true });
});
